<template>
  <div class="table-controls d-flex justify-content-between">
    <div>
      <b-dropdown no-caret
                  toggle-class="text-decoration-none column-select ml-16"
                  class="btn-toggle"
                  variant="link">
        <template slot="button-content">
          <i id="columns" class="text-25 text-gray-300 i-Sidebar-Window"></i>
        </template>
        <b-dropdown-form class="pt-16">
          <b-form-checkbox v-for="col in colSelectors"
                           :key="col.field"
                           v-model="col.visible"
                           @input="$emit('columnsChanged',colSelectors)"
                           class="mb-3">
            {{ col.label }}
          </b-form-checkbox>
        </b-dropdown-form>
      </b-dropdown>
      <b-popover :content="`Columns`"
                 target="columns"
                 delay="1000"
                 placement="bottom"
                 triggers="hover focus"/>


      <button class="btn btn-icon m-1 btn-toggle"
              type="button"
              id="export"
              @click="$emit('exportCsv')"><span class="ul-btn__icon"><i :class="{ filterActive: filterActive }"
                                                                        class="text-25 text-gray-300 i-Download"></i></span>
      </button>
      <b-popover :content="`Export`"
                 target="export"
                 delay="1000"
                 placement="bottom"
                 triggers="hover focus"/>
    </div>
    <div>
      <button
          v-if="!selectedRowsCount &&(sectionName == 'Client'||sectionName == 'Family'||sectionName == 'Event'||sectionName == 'Invoice'||sectionName == 'Lead'||sectionName == 'Introducer'||sectionName == 'Contact')"
          v-b-popover.hover.left="'Create New '+(sectionName||'')"
          class="btn btn-icon m-1 btn-toggle"
          type="button"
          @click="$emit('createNew')">
        <span class="ul-btn__icon"><i class="text-25 text-secondary i-Add"></i></span>
      </button>

      <div v-if="selectedRowsCount>0" class="vgt-selection-info-row clearfix table-alert__box">
        {{ selectionText }} {{ selectedRowsCount === 1 ? 'row' : 'rows' }} selected
        <b-link @click="$emit('clearSelection')">
          clear
        </b-link>
        <div class="vgt-selection-info-row__actions vgt-pull-right"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tableControls',
  props: [
    'filterActive',
    'selectedRowsCount',
    'colSelectors',
    'sectionName',
    'showMassDelete',
    'selectionText'
  ],
}
</script>

<style>
.pt-16 {
  overflow: auto;
  max-height: 300px;
}
</style>
