<template>
<div>

    <div class="vgt-wrap__footer vgt-clearfix">
      <div class="footer__row-count vgt-pull-left">
        <span class="footer__row-count__label">Rows per page</span> 
        <select autocomplete="off" name="perPageSelect" class="footer__row-count__select" v-model="paging.pageSize" @change="pageSizeChanged()">
          <option value="3">3</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="40">40</option>
          <option value="50">50</option>
      </select>
      </div>

        <div class="footer__navigation vgt-pull-right" >
            <a href="javascript:undefined" @click="previous()" tabindex="0" 
            class="footer__navigation__page-btn" 
            v-bind:class="{ disabled: paging.page == 1 }"
            >
                <span class="chevron left"></span> <span>Prev</span>
            </a> 
            <div class="footer__navigation__page-info">  
                {{paging.page}} of {{pageCount}} 
            </div> 
            <a href="javascript:undefined" @click="next()" tabindex="0" 
                class="footer__navigation__page-btn"
                        v-bind:class="{ disabled: paging.page == pageCount }"
                >
                <span>Next</span> <span class="chevron right"></span>
            </a>
        </div>

    </div> 

</div>
</template>

<script>
export default {
  name: 'tableCustomPagination',

  props: ['paging'],
  computed:{
    pageCount() {
        if (this.$props.paging.total > this.$props.paging.pageSize) {
            return Math.ceil(this.$props.paging.total / this.$props.paging.pageSize);
        }
        else {
            return 1;
        }
    }
  },
  methods:{
    previous() {
        if (this.$props.paging.page > 1){
            this.$emit('pageChanged',this.$props.paging.page-1);
        }
    },
    next() {
        if (this.$props.paging.page < this.pageCount){
            this.$emit('pageChanged',this.$props.paging.page+1);
        }
    },      
    pageSizeChanged() {
        this.$emit('pageSizeChanged',this.$props.paging.pageSize);
    },      
  },
}
</script>

<style>
</style>
