<template>
  <div class="d-flex">
    <vue-good-table
        :key="'vgt'"
        ref="vgt"
        :class="{ in: filterActive }"
        :columns="visibleColumns"
        :fixed-header="false"
        :pagination-options="{
                enabled: true,
                perPage: 50
            }"
        :rows="rows"
        :search-options="{
                enabled: true,
                trigger: 'enter',
                placeholder: 'Search this table'
            }"
        :selectOptions="{
                enabled: true,
                selectionInfoClass: 'table-alert__box',
                disableSelectInfo: true,
            }"
        class="card-style-table filter-table"
        mode="remote"
        styleClass="vgt-table"
        @on-search="onSearch"
        @on-selected-rows-change="rowSelectionChanged"
        @on-sort-change="onSortChange"
        @on-select-all="toggleAllSelected"
    >
      <template slot="pagination-bottom">
        <tableCustomPagination
            :paging="paging"
            @pageChanged="pageChanged"
            @pageSizeChanged="pageSizeChanged"
        ></tableCustomPagination>
      </template>
      <div slot="table-actions">

        <tableControls
            :key="'tableControls'"
            :colSelectors="columns"
            :filterActive="filterActive"
            :sectionName="moduleName"
            :selectedRowsCount="selectedRowsCount"
            :selectionText="allSelected?paging.total:selectedRowsCount"
            :showMassDelete="false"
            @changeFilter="filterActive = !filterActive"
            @clearSelection="clearSelection"
            @columnsChanged="columnsChanged"
            @createNew="createNew"
            @exportCsv="exportCsv"
            @massDelete="massDelete"
            @massUpdate="massUpdate"
        ></tableControls>
      </div>
      <template slot="table-row" slot-scope="props">
                <span
                    v-if="
                        props.column.field === 'name' && moduleName === 'Family'
                    "
                >
                    <router-link
                        :to="{
                            name: 'familyDetail',
                            params: { id: props.row.id }
                        }"
                    >{{ props.row.name }}</router-link
                    >
                </span>

        <span
            v-else-if="
                        props.column.field === 'clientName' &&
                        moduleName === 'Client'
                    "
        >
                    {{ props.row.clientName }}
                </span>
        <span
            v-else-if="
                        props.column.field === 'familyName' &&
                        ['Client', 'Transaction', 'Invoice'].includes(moduleName)"
        >
                    <router-link
                        :to="{
                            name: 'familyDetail',
                            params: { id: props.row.family }
                        }"
                    >{{
                        props.row.family_details
                            ? props.row.family_details.name
                            : ''
                      }}</router-link
                    >
                </span>

        <span
            v-else-if="
                        props.column.field === 'type' &&
                        moduleName === 'Transaction'
                    "
        >
                    <router-link
                        :to="{
                            name: 'IntroducerTransactionDetail',
                            params: { id: props.row.id }
                        }">{{ props.row.type }}</router-link>
        </span>
        <span
            v-else-if="
                        props.column.field === 'transaction_document' &&
                        moduleName === 'Transaction'
                    "
        >
          <ul>
            <li v-for="(document, index) in props.row.documents"
                :key="`transactionDocument${index}`">
              <a
                  @click.stop="openLink(document.file)">{{ document.reference }} ({{ document.type }})</a>
            </li>
          </ul>


        </span>
        <span
            v-else-if="
                        props.column.field === 'clients' &&
                        [
                            'Transaction',
                            'Storage',
                            'Policy',
                            'Document',
                            'Severance'
                        ].includes(moduleName)
                    "
        >
                    <span
                        v-for="(client, index) in props.row.clients"
                        :key="index"
                    >{{ clientName(client) }}<br
                    /></span>
                </span>
        <span
            v-else-if="
                        props.column.field === 'reference' &&
                        moduleName === 'Document'
                    "
        >
                    <a v-if="props.row.file"
                       :href="props.row.file"
                       target="_blank"
                    >{{ props.row.reference }}</a>
                </span>
        <span
            v-else-if="
                        props.column.field === 'clients' &&
                        moduleName === 'Event'
                    "
        >
                    <router-link
                        v-for="(client, index) in props.row.clientNames"
                        :key="index"
                        :to="{
                            name: 'clientDetail',
                            params: { id: client.id }
                        }"
                    >{{ clientName(client) }}<br
                    /></router-link>
                </span>

        <span
            v-else-if="
                        props.column.field === 'staff' && moduleName === 'Event'
                    "
        >
                    <span
                        v-for="(staff, index) in props.row.staffNames"
                        :key="index"
                    >{{ staff.name }}<br/>
                    </span>
                </span>
        <span
            v-else-if="
                        props.column.field === 'title' && moduleName === 'Event'
                    "
        >
                    <a
                        @click.stop="
                            evnt = props.row;
                            edit();
                        "
                    >{{ props.row.title }}</a
                    >
                </span>
        <span
            v-else-if="
                        props.column.field === 'status' &&
                        moduleName === 'Invoice'
                    "
        >
                    <router-link
                        :to="{
                            name: 'invoiceDetail',
                            params: { id: props.row.id }
                        }"
                    >{{ props.row.status }}</router-link
                    >
                </span>
        <span
            v-else-if="
                        props.column.field === 'company' &&
                        moduleName === 'Lead'
                    "
        >
                    <router-link
                        :to="{
                            name: 'leadDetail',
                            params: { id: props.row.id }
                        }"
                    >{{ props.row.company }}</router-link
                    >
                </span>
        <span
            v-else-if="
                        props.column.field === 'introducer' &&
                        moduleName === 'Introducer'
                    "
        >
                    <router-link
                        :to="{
                            name: 'introducerDetail',
                            params: { id: props.row.id }
                        }"
                    >{{ props.row.introducer }}</router-link
                    >
                </span>
        <span
            v-else-if="
                        props.column.field === 'full_name' &&
                        moduleName === 'Contact'
                    "
        >
                    <router-link
                        :to="{
                            name: 'contactDetail',
                            params: { id: props.row.id }
                        }"
                    >{{ props.row.full_name }}</router-link
                    >
                </span>
        <span
            v-else-if="
                        props.column.field === 'name' &&
                        moduleName === 'Source'
                    "
        >
                    <router-link
                        :to="{
                            name: 'sourceDetail',
                            params: { id: props.row.id }
                        }"
                    >{{ props.row.name }}</router-link
                    >
                </span>
        <span
            v-else-if="
                        props.column.field === 'transaction' &&
                        [
                            'Invoice',
                            'Will',
                            'Lpa',
                            'Trust',
                            'Policy',
                            'Document',
                            'Severance',
                            'Expression'
                        ].includes(moduleName)
                    "
        >
                    <template v-if="props.row.transaction">
                        <div class="pb-10">
                            <router-link
                                :to="{
                                    name: 'IntroducerTransactionDetail',
                                    params: {
                                        id: props.row.transaction.id
                                    }
                                }"
                            >{{ props.row.transaction.type }}</router-link
                            >
                        </div>
                        <router-link
                            v-for="(client, index) in props.row.transaction
                                .clients"
                            :key="index"
                            :to="{
                                name: 'transaction',
                                params: { id: props.row.transaction.id }
                            }"
                        >{{ clientName(client) }}<br
                        /></router-link>
                    </template>
                </span>
        <span
            v-else-if="
                        props.column.field === 'previous_address' &&
                        moduleName === 'Client' &&
                        props.row.previousAddress &&
                        props.row.previousAddress.length > 0
                    "
        >
                    {{ props.row.previousAddress[0].address_details.line_1 }}
                </span>
        <span
            v-else-if="
                        props.column.field === 'status' &&
                        [
                            'Will',
                            'Policy',
                            'Storage',
                            'Lpa',
                            'Severance',
                            'Expression'
                        ].includes(moduleName)
                    "
        >
                    <router-link
                        :to="{
                            name: moduleName.toLowerCase(),
                            params: { pk: props.row.id }
                        }"
                    >{{ props.row.status }}</router-link
                    >
                </span>
        <span
            v-else-if="
                        props.column.field === 'mirror_will.client.clientName'
                    "
        >
                    <router-link
                        v-if="props.row.mirror_will"
                        :to="{
                            name: 'will',
                            params: { pk: props.row.mirror_will.id }
                        }"
                    >{{
                        props.row.mirror_will.client.clientName
                      }}</router-link
                    >
                </span>
        <span
            v-else-if="
                        props.column.field === 'mirror_lpa.client.clientName'
                    "
        >
                    <router-link
                        v-if="props.row.mirror_lpa"
                        :to="{
                            name: 'lpa',
                            params: { pk: props.row.mirror_lpa.id }
                        }"
                    >{{
                        props.row.mirror_lpa.client.clientName
                      }}</router-link
                    >
                </span>
        <span
            v-else-if="
                        props.column.field === 'description' &&
                        ['Task'].includes(moduleName)
                    "
        >
                    <router-link :to="getParentLink(props.row)">{{
                        props.row.description
                      }}</router-link>
                </span>
        <span v-else-if="props.column.type === 'datetime'">
                    {{
            formattedDate(
                props.formattedRow[props.column.field],
                props.column.dateOutputFormat
            )
          }}
                </span>
        <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
      </template>
    </vue-good-table>


  </div>
</template>

<script>
import moment from 'moment';
import {http} from '@/services';
import {clientHelpers} from '@/mixins/clientHelpers';
import tableControls from '@/components/common/other/table/helpers/tableControls';
import tableCustomPagination from '@/components/common/other/table/helpers/tableCustomPagination';

export default {
  name: 'TableComponent',
  components: {
    // tableFilters,
    tableControls,
    tableCustomPagination
  },
  mixins: [clientHelpers],
  props: ['section', 'sectionRoot', 'moduleName', 'deleteUrl', 'updateUrl'],
  watch: {
    $route() {
      this.selectedConfig =
          'configid' in this.$route.params
              ? this.$route.params.configid
              : 0;
      this.getTableGenerator();
    },
    columns(val) {
      this.visibleColumns = val.filter((i) => i.visible);
    },
  },
  data() {
    return {
      visibleColumns: null,
      selectedConfig:
          'configid' in this.$route.params
              ? this.$route.params.configid
              : 0,
      configName: '',
      configDefault: false,
      filterActive: false,
      selectedRowsCount: 0,
      selectedRows: [],
      configList: [],
      columns: [],
      rows: [],
      paging: {
        page: 1,
        total: 0,
        pageSize: 10,
        orderBy: ''
      },
      pagingOptions: {
        enabled: true,
        perPage: 50
      },
      filter: '',
      quickFilterFields: '',
      quickFilterValue: '',
      evnt: {
        clients: [],
        owner: this.current_user(),
        status: 'Scheduled',
        repeatFreq: ''
      },
      taskFilterFields: [],
      allSelected: false,
    };
  },
  created() {
    this.visibleColumns = this.columns.filter((i) => i.visible);
    this.getConfigList();
    this.getTableGenerator();
  },
  methods: {
    clearSelection() {
      this.selectedRowsCount = 0;
      this.selectedRows = [];

      this.$refs.vgt.unselectAllInternal();

    },
    toggleAllSelected(params) {
      this.allSelected = params.selected
    },
    columnsChanged(val) {
      this.visibleColumns = val.filter((i) => i.visible);
    },
    getParentLink(task) {
      let parent = {name: '', params: {pk: 0, id: 0}};
      if (task.client) {
        parent.params.id = task.client;
        parent.name = 'clientDetail';
      } else if (task.family) {
        parent.params.id = task.family;
        parent.name = 'familyDetail';
      } else if (task.invoice) {
        parent.params.id = task.invoice;
        parent.name = 'invoiceDetail';
      } else if (task.lpa) {
        parent.params.id = task.lpa;
        parent.name = 'lpa';
      } else if (task.policy) {
        parent.params.id = task.policy;
        parent.name = 'policy';
      } else if (task.storage) {
        parent.params.id = task.storage;
        parent.name = 'storage';
      } else if (task.transaction) {
        parent.params.id = task.transaction;
        parent.name = 'transaction';
      } else if (task.will) {
        parent.params.id = task.will;
        parent.name = 'will';
      }
      parent.params.pk = parent.params.id;
      return parent;
    },
    current_user() {
      if (this.$store.getters.user) {
        return this.$store.getters.user.id;
      }
      return null;
    },
    selectedConfigChanged(id) {
      this.selectedConfig = id;
      this.getTableGenerator();
    },
    createNew() {
      if (this.moduleName === 'Event') {
        this.evnt = {
          clients: [],
          owner: this.current_user(),
          status: 'Scheduled',
          repeatFreq: ''
        };
      }
      this.$bvModal.show('add-' + this.moduleName + '-modal');
    },
    edit() {
      this.evnt.startTime = moment(
          this.evnt.startTime,
          'HH:mm:ss'
      ).format('HH:mm');
      this.evnt.endTime = moment(this.evnt.endTime, 'HH:mm:ss').format(
          'HH:mm'
      );

      this.$bvModal.show('add-' + this.moduleName + '-modal');
    },
    getConfigList() {
      let defaultOption = {
        value: 0,
        text: 'New View',
        isdefault: false
      };
      this.configList = [...[defaultOption]];

      http.get(`tableconfiglist?section=` + this.section)
          .then((response) => {
            if (response.data) {
              this.configList = [
                ...this.configList,
                ...response.data.map((x) => ({
                  value: x.id,
                  text: x.name,
                  isdefault: x.isdefault
                }))
              ];
            }
          })
          .catch((e) => {
            console.log(e);
          });
    },
    async getTableGenerator() {
      http.get(`table_generator?model=` + this.section)
          .then((response) => {
            this.columns = [];

            response.data.forEach((element) => {
              const columnElement = JSON.parse(JSON.stringify(element));

              columnElement.sortable = true;
              if (element.type == 'encrypted' || element.type == 'hidden') {
                columnElement.sortable = false;
              }
              columnElement.label = element.label ? element.label : element.field;
              columnElement.visible = element.visible;

              if (element.helper) {
                if (element.helper.label) {
                  columnElement.label = element.helper.label;
                }
                if (element.helper.visible) {
                  columnElement.visible = element.helper.visible;
                }
                if (element.helper.dateInputFormat) {
                  columnElement.dateInputFormat =
                      element.helper.dateInputFormat;
                }
                if (element.helper.dateOutputFormat) {
                  columnElement.dateOutputFormat =
                      element.helper.dateOutputFormat;
                }
                if (element.helper.sourceField) {
                  columnElement.field = element.helper.sourceField;
                }
                if (element.helper.condition) {
                  element.helper.condition;
                }
              }
              this.columns.push(columnElement);
            });

            this.loadRows();

          })
          .catch((e) => {
            console.log(e);
          });

    },
    clear() {
      this.configName = '';
    },

    loadRows() {
      const gullPreLoading = document.getElementById('loading_wrap');
      if (gullPreLoading) {
        gullPreLoading.style.display = 'block';
        gullPreLoading.style.zIndex = 1000;
      }

      http.get(
          this.sectionRoot +
          `?page=` +
          this.paging.page +
          `&size=` +
          this.paging.pageSize +
          `&orderby=` +
          this.paging.orderBy +
          `&quickFilterFields=` +
          this.quickFilterFields +
          `&quickFilterValue=` +
          escape(this.quickFilterValue)
      )
          .then((response) => {
            if (gullPreLoading) {
              gullPreLoading.style.display = 'none';
            }
            if (response.data) {
              this.rows = response.data.results;
              this.paging.total = response.data.count;
            }
          })
          .catch((e) => {
            console.log(e);
            if (gullPreLoading) {
              gullPreLoading.style.display = 'none';
            }
          });
    },
    exportCsv() {
      // this.loadRows(true,false);

      const gullPreLoading = document.getElementById('loading_wrap');
      if (gullPreLoading) {
        gullPreLoading.style.display = 'block';
        gullPreLoading.style.zIndex = 1000;
      }
      http.get(
          this.sectionRoot +
          `_csv?&orderby=` +
          this.paging.orderBy +
          this.filter +
          `&csv_fields=` +
          (this.visibleColumns.map(c => c.field).join(',')) +
          `&csv_labels=` +
          escape(this.visibleColumns.map(c => c.label).join(',')) +
          `&quickFilterFields=` +
          this.quickFilterFields +
          `&quickFilterValue=` +
          escape(this.quickFilterValue)
      )
          .then((response) => {

            const type = response.headers['content-type'];
            const blob = new Blob([response.data], {
              type: type,
              encoding: 'UTF-8'
            });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${this.sectionRoot}_export.csv`;
            link.click();
            link.remove();
            if (gullPreLoading) {
              gullPreLoading.style.display = 'none';
            }

          })
          .catch((e) => {
            console.log(e);
            if (gullPreLoading) {
              gullPreLoading.style.display = 'none';
            }
          });


    },
    massDelete() {
      this.$bvModal
          .msgBoxConfirm(
              'Are you sure that want to delete this ' +
              this.selectedRowsCount +
              ' records?',
              {
                title: 'Please Confirm',
                size: 'sm',
                buttonSize: 'sm',
                cancelVariant: 'outline-primary',
                okVariant: 'secondary',
                okTitle: 'Confirm',
                cancelTitle: 'Cancel',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
              }
          )
          .then((value) => {
            if (value) {
              //TODO: this section needs to re-code
              if (this.deleteUrl) {
                const promises = this.selectedRows.map((x) => {
                  return http.delete(this.deleteUrl, {
                    params: {id: x.id}
                  });
                });
                Promise.all(promises).then(() => {
                  this.loadRows();
                });
              } else {
                const promises = this.selectedRows.map((x) => {
                  return http.delete(
                      this.sectionRoot + `/` + x.id
                  );
                });
                Promise.all(promises).then(() => {
                  this.loadRows();
                });
              }
            }
          })
          .catch((err) => {
            // An error occurred
            console.log(err);
          });
    },
    massUpdate() {
      this.$bvModal.show('mass-update-modal');
    },
    massUpdated(list) {

      this.$bvModal
          .msgBoxConfirm(
              'Are you sure that want to update this ' +
              (this.allSelected ? this.paging.total : this.selectedRowsCount) +
              ' records?',
              {
                title: 'Please Confirm',
                size: 'sm',
                buttonSize: 'sm',
                cancelVariant: 'outline-primary',
                okVariant: 'secondary',
                okTitle: 'Confirm',
                cancelTitle: 'Cancel',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
              }
          )
          .then((value) => {
            if (value) {

              const gullPreLoading = document.getElementById('loading_wrap');
              if (gullPreLoading) {
                gullPreLoading.style.display = 'block';
                gullPreLoading.style.zIndex = 1000;
              }


              let updatingRecords = [];
              if (this.allSelected) {
                return http.get(
                    this.sectionRoot +
                    `?page=1` +
                    `&size=999999` +
                    `&orderby=` +
                    this.paging.orderBy +
                    this.filter +
                    `&quickFilterFields=` +
                    this.quickFilterFields +
                    `&quickFilterValue=` +
                    escape(this.quickFilterValue)
                )
                    .then((response) => {
                      if (response.data) {
                        updatingRecords = response.data.results.map(x => x.id);
                        this.finishUpdate(updatingRecords, list);
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                    });
              } else {
                updatingRecords = this.selectedRows.map(x => x.id);
                this.finishUpdate(updatingRecords, list);
              }
            }
          })
          .catch((err) => {
            // An error occurred
            console.log(err);
          });


    },
    finishUpdate(updatingRecords, list) {
      if (this.updateUrl) {
        const promises = updatingRecords.map((x) => {
          let data = {
            id: x
          };
          list.forEach(f => {
            data[f.fieldName.replace('__', '_')] = f.updateValue;
          })

          return http.patch(this.updateUrl, data);
        });
        Promise.all(promises).then(() => {
          this.loadRows();
        });
      } else {
        const promises = updatingRecords.map((x) => {
          let data = {
            id: x
          };
          list.forEach(f => {
            data[f.fieldName.replace('__', '_')] = f.updateValue;
          })
          return http.patch(
              this.sectionRoot + `/` + x, data
          );
        });
        Promise.all(promises).then(() => {
          this.loadRows();
        });
      }
    },
    rowSelectionChanged(params) {
      if (params && params.selectedRows) {
        this.selectedRows = params.selectedRows;
        this.selectedRowsCount = params.selectedRows.length;
        if (this.selectedRowsCount < this.rows.length) {
          this.allSelected = false;
        }
      }
    },
    pageChanged(value) {
      this.paging.page = value;
      this.loadRows();
    },
    pageSizeChanged(value) {
      this.paging.pageSize = value;
      this.pagingOptions.perPage = value;
      this.loadRows();
    },
    onSortChange(params) {
      let field = this.columns.find((x) => x.field == params[0].field);
      if (field) {
        this.paging.orderBy =
            (params[0].type == 'desc' ? '-' : '') + params[0].field;
        if (field.helper && field.helper.filterField) {
          this.paging.orderBy =
              (params[0].type == 'desc' ? '-' : '') +
              field.helper.filterField;
        }
      }
      this.loadRows();
    },
    onSearch(params) {
      let cols = this.visibleColumns.filter(
          (x) =>
              x.condition == 'icontains' ||
              x.condition == 'iexact' ||
              (x.helper &&
                  x.helper.condition &&
                  (x.helper.condition == 'icontains' ||
                      x.helper.condition == 'iexact'))
      );
      let quickFilterFields = [];
      cols.forEach((x) => {
        if(!x.no_filtering){
          if (x.helper && x.helper.filterField) {
            quickFilterFields.push(x.helper.filterField);
          } else if (x.filterField) {
            quickFilterFields.push(x.filterField);
          } else {
            quickFilterFields.push(x.field);
          }
        }
      });

      if (
          quickFilterFields.indexOf('client__profileNameFirst') > -1 &&
          quickFilterFields.indexOf('client__profileNameLast') == -1
      ) {
        quickFilterFields.push('client__profileNameLast');
      }

      this.quickFilterFields = quickFilterFields.join(',');
      this.quickFilterValue = params.searchTerm;
      this.paging.page = 1;
      this.loadRows();
    },
    formattedDate(theDate, formatStr) {
      if (theDate && moment.isDate(new Date(theDate))) {
        return moment(theDate).format(formatStr);
      } else {
        return '-';
      }
    },
    openLink(url) {
      window.open(url, '_blank')
    }
  },
  computed: {
    isSuperAdmin() {
      if (this.$store.getters.staff) {
        let profile = this.$store.getters.staff.find(
            (member) =>
                member.id == this.$store.getters.user.id &&
                member.profile == 'Super Administrator'
        );
        if (profile) {
          return true;
        }
      }
      return false;
    },

  }
};


Object.byString = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (o && k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

</script>

