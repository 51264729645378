var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},[_c('vue-good-table',{key:'vgt',ref:"vgt",staticClass:"card-style-table filter-table",class:{ in: _vm.filterActive },attrs:{"columns":_vm.visibleColumns,"fixed-header":false,"pagination-options":{
              enabled: true,
              perPage: 50
          },"rows":_vm.rows,"search-options":{
              enabled: true,
              trigger: 'enter',
              placeholder: 'Search this table'
          },"selectOptions":{
              enabled: true,
              selectionInfoClass: 'table-alert__box',
              disableSelectInfo: true,
          },"mode":"remote","styleClass":"vgt-table"},on:{"on-search":_vm.onSearch,"on-selected-rows-change":_vm.rowSelectionChanged,"on-sort-change":_vm.onSortChange,"on-select-all":_vm.toggleAllSelected},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(
                      props.column.field === 'name' && _vm.moduleName === 'Family'
                  )?_c('span',[_c('router-link',{attrs:{"to":{
                          name: 'familyDetail',
                          params: { id: props.row.id }
                      }}},[_vm._v(_vm._s(props.row.name))])],1):(
                      props.column.field === 'clientName' &&
                      _vm.moduleName === 'Client'
                  )?_c('span',[_vm._v(" "+_vm._s(props.row.clientName)+" ")]):(
                      props.column.field === 'familyName' &&
                      ['Client', 'Transaction', 'Invoice'].includes(_vm.moduleName))?_c('span',[_c('router-link',{attrs:{"to":{
                          name: 'familyDetail',
                          params: { id: props.row.family }
                      }}},[_vm._v(_vm._s(props.row.family_details ? props.row.family_details.name : ''))])],1):(
                      props.column.field === 'type' &&
                      _vm.moduleName === 'Transaction'
                  )?_c('span',[_c('router-link',{attrs:{"to":{
                          name: 'IntroducerTransactionDetail',
                          params: { id: props.row.id }
                      }}},[_vm._v(_vm._s(props.row.type))])],1):(
                      props.column.field === 'transaction_document' &&
                      _vm.moduleName === 'Transaction'
                  )?_c('span',[_c('ul',_vm._l((props.row.documents),function(document,index){return _c('li',{key:`transactionDocument${index}`},[_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.openLink(document.file)}}},[_vm._v(_vm._s(document.reference)+" ("+_vm._s(document.type)+")")])])}),0)]):(
                      props.column.field === 'clients' &&
                      [
                          'Transaction',
                          'Storage',
                          'Policy',
                          'Document',
                          'Severance'
                      ].includes(_vm.moduleName)
                  )?_c('span',_vm._l((props.row.clients),function(client,index){return _c('span',{key:index},[_vm._v(_vm._s(_vm.clientName(client))),_c('br')])}),0):(
                      props.column.field === 'reference' &&
                      _vm.moduleName === 'Document'
                  )?_c('span',[(props.row.file)?_c('a',{attrs:{"href":props.row.file,"target":"_blank"}},[_vm._v(_vm._s(props.row.reference))]):_vm._e()]):(
                      props.column.field === 'clients' &&
                      _vm.moduleName === 'Event'
                  )?_c('span',_vm._l((props.row.clientNames),function(client,index){return _c('router-link',{key:index,attrs:{"to":{
                          name: 'clientDetail',
                          params: { id: client.id }
                      }}},[_vm._v(_vm._s(_vm.clientName(client))),_c('br')])}),1):(
                      props.column.field === 'staff' && _vm.moduleName === 'Event'
                  )?_c('span',_vm._l((props.row.staffNames),function(staff,index){return _c('span',{key:index},[_vm._v(_vm._s(staff.name)),_c('br')])}),0):(
                      props.column.field === 'title' && _vm.moduleName === 'Event'
                  )?_c('span',[_c('a',{on:{"click":function($event){$event.stopPropagation();_vm.evnt = props.row;
                          _vm.edit();}}},[_vm._v(_vm._s(props.row.title))])]):(
                      props.column.field === 'status' &&
                      _vm.moduleName === 'Invoice'
                  )?_c('span',[_c('router-link',{attrs:{"to":{
                          name: 'invoiceDetail',
                          params: { id: props.row.id }
                      }}},[_vm._v(_vm._s(props.row.status))])],1):(
                      props.column.field === 'company' &&
                      _vm.moduleName === 'Lead'
                  )?_c('span',[_c('router-link',{attrs:{"to":{
                          name: 'leadDetail',
                          params: { id: props.row.id }
                      }}},[_vm._v(_vm._s(props.row.company))])],1):(
                      props.column.field === 'introducer' &&
                      _vm.moduleName === 'Introducer'
                  )?_c('span',[_c('router-link',{attrs:{"to":{
                          name: 'introducerDetail',
                          params: { id: props.row.id }
                      }}},[_vm._v(_vm._s(props.row.introducer))])],1):(
                      props.column.field === 'full_name' &&
                      _vm.moduleName === 'Contact'
                  )?_c('span',[_c('router-link',{attrs:{"to":{
                          name: 'contactDetail',
                          params: { id: props.row.id }
                      }}},[_vm._v(_vm._s(props.row.full_name))])],1):(
                      props.column.field === 'name' &&
                      _vm.moduleName === 'Source'
                  )?_c('span',[_c('router-link',{attrs:{"to":{
                          name: 'sourceDetail',
                          params: { id: props.row.id }
                      }}},[_vm._v(_vm._s(props.row.name))])],1):(
                      props.column.field === 'transaction' &&
                      [
                          'Invoice',
                          'Will',
                          'Lpa',
                          'Trust',
                          'Policy',
                          'Document',
                          'Severance',
                          'Expression'
                      ].includes(_vm.moduleName)
                  )?_c('span',[(props.row.transaction)?[_c('div',{staticClass:"pb-10"},[_c('router-link',{attrs:{"to":{
                                  name: 'IntroducerTransactionDetail',
                                  params: {
                                      id: props.row.transaction.id
                                  }
                              }}},[_vm._v(_vm._s(props.row.transaction.type))])],1),_vm._l((props.row.transaction
                              .clients),function(client,index){return _c('router-link',{key:index,attrs:{"to":{
                              name: 'transaction',
                              params: { id: props.row.transaction.id }
                          }}},[_vm._v(_vm._s(_vm.clientName(client))),_c('br')])})]:_vm._e()],2):(
                      props.column.field === 'previous_address' &&
                      _vm.moduleName === 'Client' &&
                      props.row.previousAddress &&
                      props.row.previousAddress.length > 0
                  )?_c('span',[_vm._v(" "+_vm._s(props.row.previousAddress[0].address_details.line_1)+" ")]):(
                      props.column.field === 'status' &&
                      [
                          'Will',
                          'Policy',
                          'Storage',
                          'Lpa',
                          'Severance',
                          'Expression'
                      ].includes(_vm.moduleName)
                  )?_c('span',[_c('router-link',{attrs:{"to":{
                          name: _vm.moduleName.toLowerCase(),
                          params: { pk: props.row.id }
                      }}},[_vm._v(_vm._s(props.row.status))])],1):(
                      props.column.field === 'mirror_will.client.clientName'
                  )?_c('span',[(props.row.mirror_will)?_c('router-link',{attrs:{"to":{
                          name: 'will',
                          params: { pk: props.row.mirror_will.id }
                      }}},[_vm._v(_vm._s(props.row.mirror_will.client.clientName))]):_vm._e()],1):(
                      props.column.field === 'mirror_lpa.client.clientName'
                  )?_c('span',[(props.row.mirror_lpa)?_c('router-link',{attrs:{"to":{
                          name: 'lpa',
                          params: { pk: props.row.mirror_lpa.id }
                      }}},[_vm._v(_vm._s(props.row.mirror_lpa.client.clientName))]):_vm._e()],1):(
                      props.column.field === 'description' &&
                      ['Task'].includes(_vm.moduleName)
                  )?_c('span',[_c('router-link',{attrs:{"to":_vm.getParentLink(props.row)}},[_vm._v(_vm._s(props.row.description))])],1):(props.column.type === 'datetime')?_c('span',[_vm._v(" "+_vm._s(_vm.formattedDate( props.formattedRow[props.column.field], props.column.dateOutputFormat ))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('template',{slot:"pagination-bottom"},[_c('tableCustomPagination',{attrs:{"paging":_vm.paging},on:{"pageChanged":_vm.pageChanged,"pageSizeChanged":_vm.pageSizeChanged}})],1),_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('tableControls',{key:'tableControls',attrs:{"colSelectors":_vm.columns,"filterActive":_vm.filterActive,"sectionName":_vm.moduleName,"selectedRowsCount":_vm.selectedRowsCount,"selectionText":_vm.allSelected?_vm.paging.total:_vm.selectedRowsCount,"showMassDelete":false},on:{"changeFilter":function($event){_vm.filterActive = !_vm.filterActive},"clearSelection":_vm.clearSelection,"columnsChanged":_vm.columnsChanged,"createNew":_vm.createNew,"exportCsv":_vm.exportCsv,"massDelete":_vm.massDelete,"massUpdate":_vm.massUpdate}})],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }